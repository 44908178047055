
























import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { Component, Vue } from 'vue-property-decorator'
import InputTextarea from '@/components/Controls/InputTextarea.vue'
import InputNumber from '@/components/Controls/InputNumber.vue'

@Component({
  components: {
    ValidationProvider,
    InputNumber,
    InputTextarea
  }
})
export default class CommonVacantLand extends Vue {
  formData () {
    return {
      EstimatedValue: this.estimatedValue,
      CurrentRental: this.currentRental,
      Other: this.other
    }
  }

  loadData (data: any) {
    this.estimatedValue = data.EstimatedValue
    this.currentRental = data.CurrentRental
    this.other = data.Other
  }

  propertyType () {
    return 'VacantLand'
  }

  estimatedValue: any = null
  currentRental: any = null
  other: any = null
}
